import { navigate } from "gatsby";
import * as React from "react";

const IndexPage = () => {
  React.useEffect(() => {
    navigate("/theater");
  }, []);
  return <div></div>;
};

export default IndexPage;
